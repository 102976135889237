import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router/index'
import '@/styles/index.scss'
import '@/assets/iconfont/iconfont.css'
import '@/styles/fonts.scss' // 全局引入css文件

import VueLazyload from 'vue-lazyload';

Vue.use(VueLazyload, { preLoad: 1.3, attempt: 1 });
Vue.use(ElementUI);
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
